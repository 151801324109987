global.wlAngularDeps = [
  "ngAnimate",
];

global.loadAppDeps = function () {
  require('angular-animate');
  require('../../assets/javascripts/whitelabel/uob/locales');
  require('../../assets/javascripts/whitelabel/uob/controllers/uob-controller');
  require('../../assets/javascripts/whitelabel/uob/components');
}

require('../application');

import { UrlUtils } from "booking_app/utils";

export class UobLandingPageController {
  static $inject = [
    "UrlUtils",
    "$rootScope",
  ];

  constructor(
    private $rootScope: any,
    private urlUtils: UrlUtils,
  ) { }

  isKnownUser(): boolean {
    return $.cookie().known;
  }
}

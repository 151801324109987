import { AppSettings } from "booking_app/values/app-settings";
import { CityCountryBuilderService } from "booking_app/services/city-country-builder.service";
import { Tenant } from "booking_app/types";

declare var angular: any;

angular.module("BookingApp").config([
  "$routeProvider", "$injector", "KaligoConfig",
  ($routeProvider, $injector, KaligoConfig) => {
    $routeProvider
      .when("/company/uob-visa-card-complimentary-night-about-the-benefit-faqs", {
        controller: "PageCtrl",
        templateUrl: "/html/company/faqs2",
      }).when("/terms/uob-visa-card-complimentary-night-privacy-policy", {
        controller: "PageCtrl",
        templateUrl: "/html/terms/privacy_policy2",
      }).when("/terms/uob-visa-card-complimentary-night-terms-and-conditions", {
        controller: "PageCtrl",
        templateUrl: "/html/terms/terms_condition2",
      }).when("/terms/privacy", {
        redirectTo: "/terms/uob-visa-card-complimentary-night-privacy-policy",
      }).when("/terms/terms_condition", {
        redirectTo: "/terms/uob-visa-card-complimentary-night-terms-and-conditions",
      }).when("/company/faqs", {
        redirectTo: "/company/uob-visa-card-complimentary-night-about-the-benefit-faqs",
      });
  },
]);

class UobCtrl {

  static $inject = [
    "$rootScope",
    "AppSettings",
    "SearchSortingService",
    "CityCountryBuilderService",
  ];

  constructor(
    private $rootScope: any,
    private appSettings: AppSettings,
    private searchSortingService: any,
    private cityCountryBuilderService: CityCountryBuilderService,
  ) {
    this.appSettings.enableChildrenSearch = true;
    this.appSettings.ratingArray = [5, 4, 3];
    this.appSettings.includeUnavailableInTotalCount = false;
    this.appSettings.preFilterUnavailableHotels = true;
    this.appSettings.redirectMarketRates = false;
    this.appSettings.displayNonBookableMarketRates = true;
    this.appSettings.showCancelPolicyOnEarn = false;
    this.appSettings.checkoutPagePlaceholderText = {
      firstName: "(e.g) John",
      lastName: "Smith",
    };
    this.appSettings.maximumGuestsMessage = {
      showMessage: true,
      maximumGuests: 4,
    };
    this.appSettings.limitedCountryCityOptions = true;
    this.appSettings.onlyShowVisaCardLogo = true;
    this.appSettings.tenant = Tenant.UOB;
    this.appSettings.mobileResultPopupConfig = {
      filterIconSource: "uob/icons/filter-icon.png",
      sortIconSource: "uob/icons/sort-icon.png",
      mapIconSource: "uob/icons/map-icon.png",
    };
    this.appSettings.hotelDetailsTemplateConfig.showGroupedActions = false;
    this.appSettings.ccValidationUseCardErrorModal = true;
    this.appSettings.stripePaymentIntentsEnabled = true;
    this.appSettings.showMarketRates = false;
    this.appSettings.prismicSettings.enabled = true;
    this.appSettings.prismicSettings.faqs = "te-uob--faq";
    this.appSettings.prismicSettings.terms = "te-uob--terms";
    this.appSettings.prismicSettings.privacyPolicy = "te-uob--privacy-policy";
    this.appSettings.mobileResultPopupConfig = {
      filterIconSource: "uob/icons/filter-icon.svg",
      sortIconSource: "uob/icons/sort-icon.svg",
      mapIconSource: "uob/icons/map-icon.svg",
    };
    this.appSettings.showGulfAlert = false;

    this.$rootScope.globalState.hideUseAddressFromProfile = true;
    this.$rootScope.globalState.useSimpleLoader = true;
    this.$rootScope.globalState.customLoaderFile = "/html/whitelabel/uob/custom_loader";
    this.$rootScope.abTest = { withConfidence: "no" };
    this.$rootScope.landingPage.minBookingDays = 4;
    this.$rootScope.globalState.serviceEmail = "uobvisa@inspiredlifestyles.com";
    this.cityCountryBuilderService.buildList("uob443");

    const sortingOptions = [
      "price-hilo", "price-lohi",
      "rating-hilo", "rating-lohi",
      "best-rated", "distance-lohi",
    ];

    searchSortingService.initializePermittedSortingOptions(sortingOptions);
  }

}

angular.module("BookingApp").controller("UobCtrl", UobCtrl);

import { UobLandingPageController } from "./landing-page.controller";
import { Bindings } from "booking_app/types";

export class UobLandingPage {
  bindings: any = {
    data: Bindings.ONE_WAY,
  };

  controller: any = UobLandingPageController;
  template: string = `
    <div class="landing-screen-container">

      <div class="search-form-container">
        <hotels-search-form ng-if="$ctrl.isKnownUser()"></hotels-search-form>
        <card-verification ng-if="!$ctrl.isKnownUser()"></card-verification>
      </div>

      <div class="content-container" ng-style="$ctrl.$rootScope.landingPage.backgroundImgStyle()">
        <div class="cc-info">
          <div class="cc-info-title">
            {{ 'wl.uob.cc_info_title' | translate }}
          </div>

          <div class="cc-info-description">
            <img class="cc-info-image" cdn-path="/uob/prod/cc_face.png">
            <div>
              <ul>
                <li>{{ 'wl.cc_info_description_1' | translate }}</li>
                <li>{{ 'wl.cc_info_description_2' | translate }}</li>
                <li>{{ 'wl.cc_info_description_3' | translate }}</li>
              </ul>
            </div>
          </div>

        </div>
      </div>

    </div>
  `;
}

angular.module("BookingApp").component("uobLandingPage", new UobLandingPage());
